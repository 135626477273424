.input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
    margin: 0;
    width: 100%;
    font-size: 12px;
    color: #fff;
    height: 40px;
}

.input:focus {
    border-bottom-color: #c2f051;
    box-shadow: 0 0.2px 0 0 #c2f051;
}

.input--invalid {
    border-bottom-color: red;
    box-shadow: 0 0.2px 0 0 red;
}