.button {
	display: inline-block;
	padding: 10px 0;
	width: 152px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	border: 1px solid #c2f051;
	position: relative;
	transition: .2s;
	overflow: hidden;
	white-space: nowrap;
	font-size: 14px;
	z-index: 2
}

.button:hover {
	text-decoration: none;
	color: #000;
	box-shadow: 0 0 30px rgba(194, 240, 81, 0.702)
}

.button--hover {
	background-color: transparent
}

.button--hover:hover {
	color: #000
}

.button--hover:hover:after {
	transform: translateX(0) skew(-20deg);
	z-index: -1
}

.button--hover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: -10%;
	bottom: 0;
	width: 140%;
	transform: translateX(-100%) skew(-20deg);
	transform-origin: left;
	transition: .5s cubic-bezier(.25, .1, 0, 1);
	background: linear-gradient(to right, #c2f051 0, #c2f051 80%, rgba(194, 240, 81, 0) 100%);
	z-index: -1
}

.button--disabled {
	opacity: 0.5;
	cursor: not-allowed;
}