.section-parse__buttons {
	text-align: center;
	padding: 30px 0 0 0;
	position: relative
}

.section-parse__buttons label {
	position: absolute;
	top: 0;
	left: 0;
	color: #f00;
	padding-top: 5px;
	padding-left: 5px;
	transition: .5s;
}

.section-parse__buttons .button {
	margin: 5px;
}