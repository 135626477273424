.popup {
	width: 600px;
	height: auto;
	position: fixed;
	top: 25%;
	left: calc(50% - 300px);
	background: #1f1f1f;
	z-index: 10001;
	color: #fff;
	padding: 30px 20px;
	border-radius: 4px
}

.popup a {
	color: #c2f051
}

.popup a:hover {
	text-decoration: none;
	color: #c2f051
}

.popup__close {
	position: absolute;
	top: -30px;
	right: -30px;
	font-size: 40px;
	line-height: 20px;
	color: #c2f051;
	cursor: pointer
}

.popup__overlay {
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}

.popup__title {
	font-size: 20px;
	margin-bottom: 20px;
}