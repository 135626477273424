@keyframes loaderSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border-radius: 50%;
    border: 2px solid #c2f051;
    border-left-color: transparent;
    display: inline-block;
    animation: loaderSpin 1s infinite;

    height: 20px;
    width: 20px;
}

.loader--sm {
    height: 10px;
    width: 10px;
}

.loader--md {
    height: 20px;
    width: 20px;
}

.loader--lg {
    height: 40px;
    width: 40px;
}