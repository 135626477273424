.menu__link {
    text-decoration: none;
    padding: 10px;
    border: 1px solid transparent;
}

.menu__link--highlight {
    border-color: #c2f051;
    background-color: #c2f051;
    color: #000;
}


.menu__link--highlight:hover {
    background-color: transparent;
    color: #c2f051;
}