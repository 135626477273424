@font-face {
	src: url('./fonts/RobotoMono.ttf');
	font-family: RobotoMono
}


html {
	height: 100%
}

body {
	min-height: 100%;
	font-family: RobotoMono;
	background-color: #000
}


p {
	font-size: 14px;
	color: #fff
}


.container {
	max-width: 1150px;
	padding: 0 60px
}


.green {
	color: #c2f051;
}

.error {
	font-size: 10px;
	color: red;
}


#root {
	background-image: url("./images/planet.png");
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: bottom center;
	background-attachment: fixed;
}
