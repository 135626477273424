.encoded-section {
	margin-top: 15px;
	text-align: center
}

.encoded-section textarea {
	border: 1px solid #c2f051;
	text-align: left;
	width: 100%;
	display: block;
	margin: 0 auto;
	border-radius: 4px;
	height: 150px;
	min-height: 150px;
	padding: 10px;
	color: #fff;
	font-size: 12px;
	background-color: #1f1f1f;
}

.encoded-section textarea:focus {
	border: 1px solid #c2f051;
	outline: none
}

.encoded-section .button {
	margin: 20px auto;
	color: #fff;
}